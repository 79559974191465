@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amatic+SC&display=swap');
@import url('https://fonts.cdnfonts.com/css/kawoszeh');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --white: #fff;
  --black: #000;
  --sisal: #d6cdbf;
  --shark: #252729;
  --driftwood: #a27d4d;
  --oldcoloper: #7b5334;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Raleway';
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
